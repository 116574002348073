module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div id="georap-toolbar-main">\n\n  <div class="btn-toolbar" role="toolbar">\n    <div class="dropdown btn-group">\n      <button id="georap-mainmenu" type="button" data-toggle="dropdown" class="dropdown-toggle btn btn-default btn-lg" aria-haspopup="true" aria-expanded="false">\n        ' +
((__t = ( glyphicon({ name: 'th-list' }) )) == null ? '' : __t) +
'\n        <span class="caret"></span>\n      </button>\n      <ul class="dropdown-menu" aria-labelledby="georap-mainmenu">\n        <li>\n          <a id="georap-mainmenu-add" href="#">' +
((__t = ( glyphicon({ name: 'map-marker' }) )) == null ? '' : __t) +
' Add Location...</a>\n        </li>\n        <li>\n          <a href="/crosshair">' +
((__t = ( glyphicon({ name: 'screenshot' }) )) == null ? '' : __t) +
' Coordinates...</a>\n        </li>\n        <li role="separator" class="divider"></li>\n        <li>\n          <a href="/latest">' +
((__t = ( glyphicon({ name: 'bell' }) )) == null ? '' : __t) +
' Latest</a>\n        </li>\n        <li>\n          <a href="/users">' +
((__t = ( glyphicon({ name: 'user' }) )) == null ? '' : __t) +
' Explorers</a>\n        </li>\n        <li>\n          <a href="/search">' +
((__t = ( glyphicon({ name: 'th-list' }) )) == null ? '' : __t) +
' Locations</a>\n        </li>\n        <li>\n          <a href="/statistics">' +
((__t = ( glyphicon({ name: 'signal' }) )) == null ? '' : __t) +
' Statistics</a>\n        </li>\n        <li role="separator" class="divider"></li>\n        <li>\n          <a href="/import">' +
((__t = ( glyphicon({ name: 'import' }) )) == null ? '' : __t) +
' Import...</a>\n        </li>\n        <li>\n          <a href="/export">' +
((__t = ( glyphicon({ name: 'export' }) )) == null ? '' : __t) +
' Export...</a>\n        </li>\n\n        <li role="separator" class="divider"></li>\n\n        ';
 if (user.admin) { ;
__p += '\n          <li class="dropdown-header">Admin Only</li>\n          <li>\n            <a href="/invite">' +
((__t = ( glyphicon({ name: 'envelope' }) )) == null ? '' : __t) +
' Invite...</a>\n          </li>\n          <li>\n            <a href="/admin/users">' +
((__t = ( glyphicon({ name: 'user' }) )) == null ? '' : __t) +
' Manage Users...</a>\n          </li>\n          <li role="separator" class="divider"></li>\n        ';
 } ;
__p += '\n\n        ';
 if (config.enableSupportPage) { ;
__p += '\n          <li>\n            <a href="/fund">' +
((__t = ( glyphicon({ name: 'star' }) )) == null ? '' : __t) +
' ' +
((__t = ( config.supportButtonTitle )) == null ? '' : __t) +
'</a>\n          </li>\n          <li role="separator" class="divider"></li>\n        ';
 } ;
__p += '\n\n        <li class="dropdown-header">' +
((__t = ( user.name )) == null ? '' : __t) +
'</li>\n        <li>\n          <a href="/account">' +
((__t = ( glyphicon({ name: 'user' }) )) == null ? '' : __t) +
' Account Settings</a>\n        </li>\n        <li>\n          <a href="/login">' +
((__t = ( glyphicon({ name: 'off' }) )) == null ? '' : __t) +
' Log out</a>\n        </li>\n      </ul>\n    </div><!-- .dropdown -->\n\n    <div class="btn-group" role="group" aria-label="Filter tools">\n      <button id="georap-mainmenu-filter" class="btn btn-default btn-lg" type="button" title="Filter">' +
((__t = ( glyphicon({ name: 'filter' }) )) == null ? '' : __t) +
'\n        <span class="label label-danger' +
((__t = ( isFilterActive ? '' : ' hidden' )) == null ? '' : __t) +
'">&nbsp;</span>\n      </button>\n    </div>\n\n    <form id="georap-mainmenu-search-form" class="input-group">\n      <input id="georap-mainmenu-search-text" type="text" class="form-control input-lg" placeholder="search...">\n      <span class="input-group-btn">\n        <button id="georap-mainmenu-search-submit" class="btn btn-default btn-lg" type="submit">' +
((__t = ( glyphicon({ name: 'search' }) )) == null ? '' : __t) +
'</button>\n      </span>\n    </form>\n  </div><!-- .btn-toolbar -->\n\n</div><!-- #main -->\n\n\n<div id="georap-toolbar-addition" class="btn-toolbar hidden" role="toolbar">\n  <div class="btn-group" role="group" aria-label="location tools">\n    <button id="georap-addition-cancel" type="button" class="btn btn-default">Cancel</button>\n  </div>\n\n  <div class="btn-group" role="group" aria-label="location tools">\n    <button id="georap-addition-create" type="button" class="btn btn-primary">Create here</button>\n  </div>\n</div>\n\n<div id="georap-toolbar-progress" class="btn-toolbar hidden" role="toolbar">\n  <div class="btn-group" role="group" style="width: 30em">\n    <div class="progress">\n      <div class="progress-bar progress-bar-striped active" role="progressbar" style="width: 100%">\n        <span class="sr-only">Waiting for server</span>\n      </div>\n    </div>\n  </div>\n</div>\n\n<div id="georap-toolbar-error" class="alert alert-danger hidden" role="alert">\n  Failed to create location. Try again later.\n</div>\n\n<div id="georap-toolbar-error-too-close" class="alert alert-danger hidden" role="alert" style="font-size: 1.2em">\n  <button type="button" class="close" aria-label="Close">\n    <span aria-hidden="true">&times;</span>\n  </button>\n  Too close to another location. Maybe you could update the existing one instead.\n</div>\n';

}
return __p
}