module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="container-fluid georap-supportfund">\n  <div class="row">\n    <div class="col-md-12">\n\n      ' +
((__t = ( content )) == null ? '' : __t) +
'\n\n    </div>\n  </div>\n</div>\n';

}
return __p
}